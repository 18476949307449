var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.insidePages.length > 0)?_c('div',{staticClass:"main h-full relative"},[_vm._l((_vm.insidePages),function(insidePage,index){return [_c('Transition',{key:`${insidePage.page}-${index}`,attrs:{"name":(_vm.$isMobile.any &&
					(index === _vm.topCardIndex || !insidePage.visible) &&
					(insidePage.config && insidePage.config.mode === 'modal'
						? 'inside-page-' + _vm.pageDirection + '-bottom'
						: 'inside-page-' + _vm.pageDirection + '-side')) ||
				undefined,"appear":""},on:{"after-enter":function($event){return _vm.transitionFinished('enter')},"after-leave":function($event){return _vm.transitionFinished('leave')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.topCardIndex || index === _vm.lastCardIndex),expression:"index === topCardIndex || index === lastCardIndex"}],key:`insidePage-${index}`,ref:"insidePage",refInFor:true,staticClass:"absolute h-full w-full overflow-y-auto flex flex-col inside-page-scroll-container",class:[
					insidePage.config && insidePage.config.mode === 'modal'
						? 'inside-modal'
						: 'inside-vertical',
					_vm.companyMode && insidePage.config && insidePage.config.mode !== 'modal'
						? 'bg-color-grey-lightest'
						: 'bg-color-white'
				],style:({
					'z-index':
						index +
						10 +
						(insidePage.config && insidePage.config.mode === 'modal'
							? 500
							: 0) /* inside page modals need to be higher than regular modals */
				})},[(insidePage.config && insidePage.config.mode === 'modal')?_c('div',{class:{ 'border-top border-color-main border-t-4': _vm.$isMobile.any }},[_c('div',{staticClass:"flex max-w-5xl m-auto"},[_c('h4',{staticClass:"flex-1 px-4 mt-4 md:text-center"},[(insidePage.config.pageTitleBefore)?_c(insidePage.config.pageTitleBefore.component,_vm._g(_vm._b({tag:"component"},'component',insidePage.config.pageTitleBefore.props,false),
									insidePage.config &&
									insidePage.config.pageTitleBefore &&
									insidePage.config.pageTitleBefore.on
								)):_vm._e(),_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(insidePage.config.modalHeading)+"\n\t\t\t\t\t\t\t"),(insidePage.config.pageTitleAfter)?_c(insidePage.config.pageTitleAfter.component,_vm._g(_vm._b({tag:"component"},'component',insidePage.config.pageTitleAfter.props,false),
									insidePage.config &&
									insidePage.config.pageTitleAfter &&
									insidePage.config.pageTitleAfter.on
								)):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"close-insidepage-mobile",on:{"click":_vm.closeInsidePage}},[_c('HokIcon',{attrs:{"icon":"icon-close-slim","color":"text","size":4,"pointer":""}})],1)])]):(!_vm.showMobileInsidePageHeader(insidePage))?_c('span',{staticClass:"fixed z-10 cursor-pointer rounded-full bg-color-white flex items-center justify-center md:-mt-1",class:{
						'close-insidepage-mobile': _vm.$isMobile.any,
						'close-insidepage-desktop': !_vm.$isMobile.any
					},on:{"click":_vm.closeInsidePage}},[_c('HokIcon',{attrs:{"icon":"icon-close-slim","color":"text","size":4,"pointer":""}})],1):_c('div',{ref:"headerBar",refInFor:true,class:{ 'mobile-header-bar': _vm.$isMobile.any },style:(_vm.$isMobile.any && !_vm.$isMobile.apple.phone
							? {
									top: '-' + _vm.getInsidePageVars(index, 'scrollOffset') + 'px',
									'border-bottom':
										_vm.getInsidePageVars(index, 'scrollDirection') === 'up' &&
										!_vm.getInsidePageVars(index, 'onTopInsidePageHeader')
											? '1px solid #d6d6d6'
											: ''
								}
							: undefined)},[_c('div',{staticClass:"flex bg-color-white"},[_c('div',{staticClass:"close-insidepage-mobile pointer",on:{"click":_vm.closeInsidePage}},[_c('HokIcon',{staticClass:"inline",attrs:{"icon":"icon-arrow-left","color":"text","size":4}}),_vm._v(" "),(insidePage.config && insidePage.config.breadcrumb)?_c('span',{staticClass:"inline-block hidden md:inline cursor-pointer text-color-main"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(insidePage.config.breadcrumb)+"\n\t\t\t\t\t\t\t")]):_vm._e()],1),_vm._v(" "),(insidePage.config)?_c('h4',{staticClass:"flex-1 text-center self-center pr-8 mb-0"},[(insidePage.config.pageTitleBefore)?_c(insidePage.config.pageTitleBefore.component,_vm._g(_vm._b({tag:"component"},'component',insidePage.config.pageTitleBefore.props,false),
									insidePage.config &&
									insidePage.config.pageTitleBefore &&
									insidePage.config.pageTitleBefore.on
								)):_vm._e(),_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(insidePage.config.pageTitle)+"\n\t\t\t\t\t\t\t"),(insidePage.config.pageTitleAfter)?_c(insidePage.config.pageTitleAfter.component,_vm._g(_vm._b({tag:"component"},'component',insidePage.config.pageTitleAfter.props,false),
									insidePage.config &&
									insidePage.config.pageTitleAfter &&
									insidePage.config.pageTitleAfter.on
								)):_vm._e()],1):_vm._e()])]),_vm._v(" "),(!insidePage.preparedComponent)?_c('Spinner',{key:`insidePage-${index}`}):_c('InsidePage',{key:`insidePageComponent-${insidePage.page}-${index}`,staticClass:"insidepage flex flex-col h-full",class:{
						'insidepage-mobile': _vm.$isMobile.any,
						'pt-12': _vm.$isMobile.any && insidePage.config && insidePage.config.pageTitle,
						'flex-1': insidePage.config && insidePage.config.mode === 'modal' /* for modals */,
						'overflow-y-auto': insidePage.config && insidePage.config.mode === 'modal' /* */
					},style:({
						'padding-top':
							_vm.$isMobile.any &&
							!_vm.$isMobile.apple.phone &&
							insidePage.config &&
							insidePage.config.pageTitle &&
							48 - _vm.getInsidePageVars(index, 'scrollOffset') + 'px'
					}),attrs:{"index":index,"component":insidePage.preparedComponent.is,"props":insidePage.preparedComponent.props,"slots":insidePage.preparedComponent.slots,"scoped-slots":insidePage.preparedComponent.scopedSlots}})],1)])]})],2):_c('div')
}
var staticRenderFns = []

export { render, staticRenderFns }