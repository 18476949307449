
import { Component, Inject, Vue, Watch, Prop } from 'vue-property-decorator';
import { ComponentPublicInstance } from 'vue';
import { VueMetaPlugin } from 'vue-meta';
import { delay } from '../../helpers/promise';
import { insidePages } from './insidePage';

const headerBarHeight = 48;

function isElement(obj: Element | ComponentPublicInstance<any> | Vue<any>): obj is Element {
	return obj && obj instanceof Element;
}

function hasVueMeta(ctx: any): ctx is { $meta(): VueMetaPlugin } {
	return typeof ctx.$meta === 'function';
}

@Component({
	name: 'InsidePageRoot'
})
export default class InsidePageRoot extends Vue {
	@Prop({ type: Boolean, default: false }) readonly companyMode!: boolean;

	insidePageVars: Record<number, any> = {};

	private ensureLastScroll: any;

	lastCardIndex: number | undefined;

	@Inject({ default: false }) page!: any;

	data() {
		return {
			lastCardIndex: undefined
		};
	}

	get getInsidePageVars() {
		return (index, key) => {
			if (index === 'current') {
				index = this.insidePages.length - 1;
			}

			this.initInsidePageVars(index);

			return this.insidePageVars[index][key];
		};
	}

	get insidePages() {
		return insidePages.pages;
	}

	get pageDirection() {
		return insidePages.navDirection;
	}

	@Watch('topCardIndex') onTopCardIndexWatched(_newVal, oldVal) {
		this.lastCardIndex = oldVal;
	}

	get topCardIndex() {
		let index = -1;
		this.insidePages.some(ip => {
			if (!ip.visible) {
				return true;
			}
			index += 1;
			return false;
		});
		return index;
	}

	@Watch('insidePages') onInsidePageChange(newValue) {
		if (Object.keys(this.insidePageVars).length > Object.keys(newValue).length) {
			// inside page removed from stack, clear up temporary variable
			Object.keys(this.insidePageVars).forEach(key => {
				if (parseInt(key, 10) >= Object.keys(newValue).length) {
					delete this.insidePageVars[key];
				}
			});
		} else {
			// new page
			Vue.nextTick(() => {
				this.myScrollToTop();
			});
		}

		try {
			if (insidePages.pages.length > 0) {
				// pause vue-meta and overwrite the document title by our own
				if (hasVueMeta(this)) {
					this.$meta().pause();
				}
				const currentPage =
					(insidePages.pages.length && insidePages.pages[insidePages.pages.length - 1]) ||
					undefined;
				const title = currentPage?.config?.pageTitle;
				document.title = `${title ? `${title} | ` : ''}hokify`;
			} else if (hasVueMeta(this)) {
				// restore original title
				this.$meta().resume();
			}
		} catch (err: any) {
			console.error('cannot set page title', err);
		}
	}

	mounted() {
		if (this.$isMobile.any) {
			window.addEventListener('scroll', this.scrollEvent.bind(this), true);
			window.addEventListener('touchstart', this.touchStart.bind(this), true);
			window.addEventListener('touchend', this.touchEnd.bind(this), true);
		}
	}

	private insidePageClosedEvent() {
		this.$nextTick(() => {
			this.$emit('compute-css-headers');
		});
	}

	beforeMount() {
		this.$page.event.$on('inside-page-closed', this.insidePageClosedEvent);

		this.$page.event.$on('scroll-to-top', this.myScrollToTop);
	}

	async myScrollToTop() {
		this.$emit('compute-css-headers');

		if (
			this.$refs.insidePage &&
			Array.isArray(this.$refs.insidePage) &&
			this.$refs.insidePage.length > 0
		) {
			const currentInsidePageIndex = this.$refs.insidePage.length - 1;
			const currentInsidePage = this.$refs.insidePage[currentInsidePageIndex];

			if (isElement(currentInsidePage)) {
				// ensure everyhting is visible, ensure animation is finished (400ms)
				await Promise.race([
					// eslint-disable-next-line no-promise-executor-return
					new Promise(resolve => this.$page.event.$on('transition-finished-enter', resolve)),
					delay(1000)
				]);
				currentInsidePage.scrollIntoView();
			}

			this.insidePageScrollToTop(currentInsidePageIndex);
		}
	}

	beforeDestroy() {
		this.$page.event.$off('inside-page-closed', this.insidePageClosedEvent);
		this.$page.event.$off('scroll-to-top', this.myScrollToTop);
		if (this.$isMobile.any) {
			window.removeEventListener('scroll', this.scrollEvent.bind(this));
			window.removeEventListener('touchstart', this.touchStart.bind(this));
			window.removeEventListener('touchend', this.touchEnd.bind(this));
		}
	}

	initInsidePageVars(index) {
		if (!this.insidePageVars[index]) {
			// init with defaults
			Vue.set(this.insidePageVars, index, {
				lastScrollPos: 0,
				lastTouchPos: 0,
				onTopInsidePageHeader: true,
				scrollDirection: 'down',
				lastScrollUpOffSet: headerBarHeight,
				scrollOffset: 0
			});
		}
	}

	showMobileInsidePageHeader(insidePage) {
		let currentInsidePage = insidePage;
		if (!currentInsidePage) {
			currentInsidePage = this.insidePages[this.insidePages.length - 1];
		}

		return currentInsidePage?.config?.pageTitle;
	}

	setInsidePageVar(insidePageIndex, key, value) {
		if (insidePageIndex === 'current') {
			insidePageIndex = this.insidePages.length - 1;
		}

		this.initInsidePageVars(insidePageIndex);

		this.insidePageVars[insidePageIndex][key] = value;
	}

	insidePageScrollToTop(insidePageIndex) {
		// reset header bar
		this.setInsidePageVar(insidePageIndex, 'lastScrollPos', 0);
		this.setInsidePageVar(insidePageIndex, 'scrollOffset', 0);

		this.setInsidePageVar(insidePageIndex, 'scrollDirection', 'down');
		this.setInsidePageVar(insidePageIndex, 'onTopInsidePageHeader', true);
	}

	touchStart($event) {
		this.setInsidePageVar(
			'current',
			'lastTouchPos',
			($event &&
				$event.changedTouches &&
				$event.changedTouches[0] &&
				$event.changedTouches[0].pageY) ||
				0
		);
	}

	touchEnd($event) {
		const te = $event && $event.changedTouches && $event.changedTouches[0].pageY;
		if (
			this.getInsidePageVars('current', 'lastTouchPos') < te - 15 &&
			this.getInsidePageVars('current', 'lastTouchPos')
		) {
			this.setInsidePageVar('current', 'lastScrollPos', 0);
			this.setInsidePageVar('current', 'scrollOffset', 0);
		}
	}

	scrollEvent($event, lastScroll?) {
		/* 2 use cases:
                  1. regular one (page and header are scrolled) -> WORKS
                  2.a has tabs (the tab component scrolls) -> WORKS
                  2.b has some other content in the page (e.g. footer) and the scorll content is inside (similar to tab) -> TODO (detect if page has scroll components in it.. shuld be doable ;))
                 */
		if (!this.$refs.headerBar) {
			return;
		}

		// header bar 48px
		let newScrollDirection;

		const scrollTop = $event && $event.target && $event.target.scrollTop;

		if (scrollTop > $event.target.scrollHeight - $event.target.clientHeight - headerBarHeight) {
			return;
		}

		// do some chill mode
		// < scrollPos

		let scrollPos;
		const lastScrollPos = this.getInsidePageVars('current', 'lastScrollPos');
		if (lastScrollPos < scrollTop) {
			// scrolling down
			scrollPos = scrollTop;
			newScrollDirection = 'down';
		} else if (lastScrollPos > scrollTop) {
			// scrolling up
			scrollPos = scrollTop;

			newScrollDirection = 'up';
		} else {
			scrollPos = scrollTop;
		}

		if (
			newScrollDirection === 'up' &&
			this.getInsidePageVars('current', 'scrollOffset') === headerBarHeight
		) {
			// reset last scroll position if header bar is not visible anymore and we scroll up
			this.setInsidePageVar('current', 'lastScrollUpOffSet', scrollPos - (headerBarHeight - 1));
			this.setInsidePageVar('current', 'scrollOffset', headerBarHeight - 1);
		} else if (
			newScrollDirection === 'down' &&
			this.getInsidePageVars('current', 'scrollOffset') === 0
		) {
			// reset last scroll position if header bar is visible and we scroll down
			this.setInsidePageVar('current', 'lastScrollUpOffSet', scrollPos);
			this.setInsidePageVar('current', 'scrollOffset', 1);
		} else {
			const offset = Math.min(
				Math.max(scrollPos - this.getInsidePageVars('current', 'lastScrollUpOffSet'), 0),
				headerBarHeight
			);

			this.setInsidePageVar('current', 'scrollOffset', offset);
		}

		if (
			newScrollDirection &&
			this.getInsidePageVars('current', 'scrollDirection') !== newScrollDirection
		) {
			this.setInsidePageVar('current', 'scrollDirection', newScrollDirection);
		}

		this.setInsidePageVar('current', 'lastScrollPos', Math.max(0, scrollPos)); // do not allow values smaller than 0
		this.setInsidePageVar('current', 'onTopInsidePageHeader', scrollPos <= headerBarHeight);

		// @todo check if really needed
		if (this.ensureLastScroll) {
			clearTimeout(this.ensureLastScroll);
		}
		if (!lastScroll) {
			this.ensureLastScroll = setTimeout(() => {
				this.scrollEvent($event, true);
			}, 300);
		}
	}

	closeInsidePage() {
		this.$page.goBack();
	}

	transitionFinished(param: 'enter' | 'leave') {
		if (param === 'enter') {
			this.lastCardIndex = undefined;
		}
		this.$page.event.$emit(`transition-finished-${param}`);
	}
}
